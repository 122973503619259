<template>
  <div>
    <company-info-navigation
      :interval-scale="dateInterval"
      tab="shipper" />

    <template v-if="suppliers.length">
      <div class="stats-graph">
        <div class="chart-row pie-chart-row">
          <top-companies-chart company-type="shippers" />
          <formatted-pie-chart
            :title="`Top Countries ${dateRangeText || ''}`"
            :data="topCountriesOfOrigin"
            :loading="$actions.pending('company/overview/fetchTopCountriesOfOrigin')"
            field-name="countries" />
        </div>
      </div>

      <companies-data-viewer
        :class="{ 'full-screen': inFullscreen }"
        :loading="$actions.pending('company/suppliers/fetchCompanySuppliers')"
        :companies="suppliers"
        :current-page="currentPage"
        :total="totalSuppliers"
        company-type="shipper"
        @page="setCurrentPage"/>
    </template>
    <company-placeholder
      v-else
      :loading="$actions.pending('company/suppliers/fetchCompanySuppliers')"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import CompanyInfoNavigation from '~/components/company/CompanyInfoNavigation'
import TopCompaniesChart from '~/components/company/overview/TopCompaniesChart'
import FormattedPieChart from '~/components/common/FormattedPieChart'
import CompaniesDataViewer from '~/components/data-viewers/CompaniesDataViewer'
import CompanyPlaceholder from '~/components/company/CompanyPlaceholder'

export default {
  components: {
    CompanyInfoNavigation,
    TopCompaniesChart,
    FormattedPieChart,
    CompaniesDataViewer,
    CompanyPlaceholder
  },
  computed: {
    ...mapState(['inFullscreen']),
    ...mapState('company/overview', ['topCountriesOfOrigin']),
    ...mapState('company/suppliers', [
      'suppliers',
      'totalSuppliers',
      'currentPage'
    ]),
    ...mapState('company', ['pageLimit', 'dateInterval']),
    ...mapGetters('company/overview', ['dateRangeText'])
  },
  watch: {
    pageLimit() {
      this.updateQueryParams()
    },
    async $route(to) {
      const { p: page, r: limit } = to.query

      if (parseInt(page) !== this.currentPage) {
        this.setCurrentPage(parseInt(page))
      }
      if (parseInt(limit) !== this.pageLimit) {
        this.setPageLimit(parseInt(limit))
      }
    }
  },
  mounted() {
    this.updateQueryParams()
  },
  updated() {
    this.updateQueryParams()
  },
  methods: {
    ...mapMutations('company', ['setPageLimit']),
    ...mapMutations('company/suppliers', ['setCurrentPage']),
    updateQueryParams() {
      if (this.$actions.pending('company/suppliers/fetchCompanySuppliers')) {
        return
      }

      const pathWithQueryParams = {
        path: this.$route.path,
        query: { p: this.currentPage, r: this.pageLimit }
      }

      this.$router.push(pathWithQueryParams)
    }
  }
}
</script>
<style src="~/assets/scss/sections/company/overview.scss" lang="scss"/>
